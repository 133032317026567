export const SEDES = [
  {
    id: "mercaderes",
    codloc: "ND",
    categoria: "PLUS",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ftrebol%2Ftrebol.jpeg?alt=media",
    gmaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7801.460300261595!2d-76.98625512418381!3d-12.130606843469037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b875b26e8f13%3A0x7da2be6906f0875d!2sCa.%20Mercaderes%20279%2C%20Santiago%20de%20Surco%2015039%2C%20Per%C3%BA!5e0!3m2!1ses-419!2ses!4v1726937687082!5m2!1ses-419!2ses",
    name: "Mercaderes",
    address: "Av. Mercaderes 279, Surco",
    phone: "908 919 799",
    description: "Llegó a Surco nuestra segunda sede de categoría plus. Una sede que redefine tu experiencia fitness con un moderno equipamiento de máquinas e implementos en ambientes diseñados para potenciar tu entrenamiento al máximo. Únete ahora en PREVENTA y sé parte de un nuevo estándar de experiencia fitness",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:30 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ]
  },
  {
    id: "trebol",
    codloc: "10",
    categoria: "EDGE",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ftrebol%2Ftrebol.jpeg?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d728.2968703549425!2d-76.98740319024955!3d-12.086554284017579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c70839363905%3A0x6124d3e0230df02d!2sGimnasio%20b2%20%7C%20El%20Tr%C3%A9bol!5e0!3m2!1ses-419!2ses!4v1712259160826!5m2!1ses-419!2ses",
    name: "El Trébol",
    address: "Av. Javier Prado Este 3616, San Borja",
    phone: "905 453 190",
    description:
      "Nuestra segunda EDGE ahora en San Borja. Ubicados a una cuadra del Trébol de Javier Prado, elevamos el estándar de tu entrenamiento a un nuevo nivel. Una sede moderna, con tecnología de vanguardia e innovación para optimizar tu rendimiento y maximizar tus resultados bajo un nuevo concepto fitness.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        "title": "CLASES GRUPALES",
        "src": "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ftrebol%2FGRUPALES-TR%C3%89BOL.png?alt=media"
      },
      {
        "title": "BALANCE STAGE",
        "src": "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ftrebol%2FBALANCE-TR%C3%89BOL.png?alt=media"
      },
      {
        "title": "INDOOR CYCLING",
        "src": "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ftrebol%2FCYCLING-TR%C3%89BOL.png?alt=media"
      },
      {
        "title": "X-JABS",
        "src": "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ftrebol%2FXJABS-TR%C3%89BOL.png?alt=media"
      }
    ]
  },
  {
    id: "barranco",
    codloc: "08",
    categoria: "EDGE",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fbarranco%2FFACHADA%20BARRANCO.jpg?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.1333113027312!2d-77.02270903037433!3d-12.144055488162891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b7ee2a44b323%3A0xdd85d9ac0e41826c!2sAv.%20Almte.%20Miguel%20Grau%20709%2C%20Lima%2015063!5e0!3m2!1ses-419!2spe!4v1694120937721!5m2!1ses-419!2spe",
    name: "Barranco",
    address: "Av. Almirante Grau 709-715, Barranco",
    phone: "905 453 167",
    email: "counter.barranco@b2.pe",
    description:
      "Sede EDGE con acceso a todas las sedes b2. Ubicados a una cuadra del malecón de Barranco, elevamos el estándar de tu entrenamiento a un nuevo nivel. Una sede moderna, con tecnología de vanguardia e innovación para optimizar tu rendimiento y maximizar tus resultados bajo un nuevo concepto fitness.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:30 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fbarranco%2FGRUPALES-BARRANCO.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fbarranco%2FBALANCE-BARRANCO.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fbarranco%2FCYCLING-BARRANCO.png?alt=media",
      },
      {
        title: "METABOOST",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fbarranco%2FMETABOOST-BARRANCO.png?alt=media",
      },
    ],
  },
  {
    id: "miraflores",
    codloc: "06",
    categoria: "PRIME",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fmiraflores%2FMIRAFLORES-01.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fmiraflores%2FHORIZONTAL%20MIRAFLORES.MP4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8705.29469135444!2d-77.06299804585667!3d-12.087616594711996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9604cdb7b17%3A0xdce045b95d1261c8!2sGimnasio%20b2%20%7C%20Sede%20Miraflores!5e0!3m2!1ses-419!2spe!4v1692583056687!5m2!1ses-419!2spe",
    name: "Miraflores",
    address: "Av. Roosevelt 6409, Miraflores",
    phone: "905 453 283",
    email: "counter.miraflores@b2.pe",
    description:
      "Sede Prime con acceso a sedes Prime, Plus y Classic. Entrena de manera exclusiva en una sede exclusiva, contemporánea y tecnológica, diseñada para disfrutar tu entrenamiento día a día. Tres pisos a tu disposición con salas amplias que te brindan libertad y comodidad para alcanzar tus metas.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fmiraflores%2FGRUPALES-MIRAFLORES.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fmiraflores%2FBALANCE-MIRAFLORES.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fmiraflores%2FCYCLING-MIRAFLORES.png?alt=media",
      },
    ],
  },
  {
    id: "chacarilla",
    codloc: "04",
    categoria: "PRIME",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fchacarilla%2FCHACARILLA-01.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FHORIZONTAL%20CAMINOS.mp4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.9836624791733!2d-76.9947929240119!3d-12.113270243144502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c7aad0739759%3A0x537ceadf9e19e00e!2sGimnasio%20b2%20Prime%20Caminos%20del%20Inca!5e0!3m2!1ses-419!2spe!4v1692327636263!5m2!1ses-419!2spe",
    name: "Chacarilla",
    address: "C.C Caminos Del Inca, Local 315, 3er piso - Santiago de Surco",
    phone: "905 453 260",
    email: "counter-ci@b2.pe",
    description:
      "Sede Prime con acceso a sedes Prime, Plus y Classic. Tu futuro de bienestar inicia en nuestra amplia sede. Ambientada para guiarte en un estilo de vida activo y moderno con la más alta tecnología fitness que redefine tus expectativas de salud y bienestar.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fchacarilla%2FGRUPALES-CHACARILLA.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fchacarilla%2FBALANCE-CHACARILLA.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fchacarilla%2FCYCLING-CHACARILLA.png?alt=media",
      },
    ],
  },
  // {
  //     id: "san-borja",
  //     codloc: "07",
  //     gmaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.027195613623!2d-77.0040827240269!3d-12.110290743088663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c7e688aa40d5%3A0x558f5fd1f4ebaea1!2sReal%20Plaza%20Primavera!5e0!3m2!1ses-419!2spe!4v1694121291565!5m2!1ses-419!2spe",
  //     name: "San Borja",
  //     address: "C.C Real Plaza Primavera - 2do nivel. Ref. Metro Limatambo",
  //     phone: "-",
  //     email: "-",
  //     description: "",
  //     schedule: [
  //         {
  //             days: "Lunes a Viernes",
  //             hours: "6:00 AM - 11:00 PM"
  //         },
  //         {
  //             days: "Sábado",
  //             hours: "8:00 AM - 6:00 PM"
  //         },
  //         {
  //             days: "Domingo",
  //             hours: "9:00 AM - 3:00 PM"
  //         },
  //     ]
  // },
  {
    id: "jesus-maria",
    codloc: "05",
    categoria: "PLUS",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fjesus-maria%2FJES%C3%9AS-MAR%C3%8DA-01.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FHORIZONTAL%20JESUS%20MARIA.mp4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7320.269434762047!2d-77.06092495573043!3d-12.086946459817442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c91906dcc949%3A0x704f04a88b6b91b7!2sGimnasio%20b2%20-%20Sede%20Jes%C3%BAs%20Mar%C3%ADa!5e0!3m2!1ses-419!2spe!4v1692583176569!5m2!1ses-419!2spe",
    name: "Jesús María",
    address: "Centro Comercial San Felipe - Local 79, Jesús María",
    phone: "905 453 084",
    email: "counter-jm@b2.pe",
    description:
      "Sede Plus con acceso a sedes Plus y Classic. Descubre las bondades de nuestra sede ubicada en una zona residencial rodeada de grandes parques y jardines, la Residencial San Felipe. Una experiencia fitness distribuida estratégicamente en dos pisos con amplias áreas de entrenamiento y relajante vista.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fjesus-maria%2FGRUPALES-JES%C3%9AS-MAR%C3%8DA.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fjesus-maria%2FBALANCE-JES%C3%9AS-MAR%C3%8DA.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fjesus-maria%2FCYCLING-JES%C3%9AS-MAR%C3%8DA.png?alt=media",
      },
    ],
  },
  {
    id: "pardo",
    codloc: "09",
    categoria: "PLUS",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fpardo%2FFACHADA%20PARDO.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fpardo%2FHORIZONTAL%20PARDO.MP4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.894972914623!2d-77.03751661464709!3d-12.119338112616571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8181d7c2f49%3A0x64d458dd21c91ba1!2sAv.%20Jos%C3%A9%20Pardo%20399%2C%20Miraflores%2015074!5e0!3m2!1ses-419!2spe!4v1694121079056!5m2!1ses-419!2spe",
    name: "Pardo La Alameda",
    address: "Av. José Pardo 399, Miraflores. Ref. La Alameda & Hacienda Club",
    phone: "905 452 998",
    email: "counter.pardo@b2.pe",
    description:
      "Sede Plus con acceso a todas las sedes Plus y Classic. Llegamos al corazón de Miraflores y nos posicionamos con la mejor opción del distrito. Entrena sin limites en una sede optimizada con el mejor equipamiento fitness y el sistema be balance.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "5:30 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fpardo%2FGRUPALES-PARDO.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fpardo%2FBALANCE-PARDO.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fpardo%2FCYCLING-PARDO.png?alt=media",
      },
    ],
  },
  {
    id: "san-miguel",
    codloc: "02",
    categoria: "PLUS",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fsan-miguel%2FSAN-MIGUEL-01.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fsan-miguel%2FHORIZONTAL%20-%20SAN%20MIGUEL.MP4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7803.040876189402!2d-77.09817153022458!3d-12.076481599999976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c96fd0d4c4d9%3A0x78c08e0893d0ac41!2sGimnasio%20sede%20Plus%20b2%20Metro%20La%20Marina!5e0!3m2!1ses-419!2spe!4v1692321338566!5m2!1ses-419!2spe",
    name: "San Miguel",
    address:
      "Av. La Marina 2500, San Miguel (Ref. 2do. Piso de Metro La Marina)",
    phone: "905 453 249",
    email: "countersm@b2.pe",
    description:
      "Sede Plus con acceso a todas las sedes Plus y Classic. Disfruta de una experiencia divertida y familiar en nuestra ubicada en el segundo nivel de la tienda Metro La Marina con instalaciones fitness de primera clase que refuerza nuestro compromiso con tu bienestar y comodidad.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "6:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fsan-miguel%2FGRUPALES-SAN-MIGUEL.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fsan-miguel%2FBALANCE-SAN-MIGUEL.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fsan-miguel%2FCYCLING-SAN-MIGUEL.png?alt=media",
      },
    ],
  },
  {
    id: "colonial",
    codloc: "01",
    categoria: "CLASSIC",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fcolonial%2FCOLONIAL-01.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fcolonial%2FHORIZONTAL%20COLONIAL.MP4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7803.820391315149!2d-77.08765409918875!3d-12.049699700000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c93621cff18f%3A0x8852cc20f44b66b2!2sGimnasio%20b2%20%7C%20Sede%20Colonial!5e0!3m2!1ses-419!2spe!4v1692583229267!5m2!1ses-419!2spe",
    name: "Colonial",
    address: "Av. Óscar R. Benavides 3002, Cercado de Lima",
    phone: "905 453 219",
    email: "counter@b2.pe",
    description:
      "Sede Classic con acceso a todas las sedes classic. La primera sede b2 mantiene un ambiente familiar y cálido que espera por ti. Disfruta de todos los beneficios de ser socio b2 optimizando tu bienestar y tu salud.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "6:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fcolonial%2FGRUPALES-COLONIAL.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fcolonial%2FBALANCE-COLONIAL.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Fcolonial%2FCYCLING-COLONIAL.png?alt=media",
      },
    ],
  },
  {
    id: "uni",
    codloc: "03",
    categoria: "CLASSIC",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Funi%2FUNI-01.jpg?alt=media",
    video:
      "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2FHORIZONTAL%20UNI.mp4?alt=media",
    gmaps:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7804.930677062555!2d-77.06086183022461!3d-12.011451899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105cfc21834005f%3A0xdea6dfb520fa2993!2sGimnasio%20b2!5e0!3m2!1ses-419!2spe!4v1692583259300!5m2!1ses-419!2spe",
    name: "UNI",
    address: "Tupac Amaru 1600, Rimac (Ref. Primer piso de Metro)",
    phone: "905 453 241",
    email: "counter.uni@b2.pe",
    description:
      "Sede Classic con acceso a todas las sedes classic. Nuestra de Lima Norte espera por ti. Entrena en el mejor gimnasio de la zona con la experiencia be balance en instalaciones cómodas y de calidad diseñadas para que disfrutes la experiencia b2.",
    schedule: [
      {
        days: "Lunes a Viernes",
        hours: "6:00 AM - 11:00 PM",
      },
      {
        days: "Sábado",
        hours: "8:00 AM - 6:00 PM",
      },
      {
        days: "Domingo",
        hours: "9:00 AM - 3:00 PM",
      },
    ],
    activities: [
      {
        title: "CLASES GRUPALES",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Funi%2FGRUPALES-UNI.png?alt=media",
      },
      {
        title: "BALANCE STAGE",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Funi%2FBALANCE-UNI.png?alt=media",
      },
      {
        title: "INDOOR CYCLING",
        src: "https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Funi%2FCYCLINNG-UNI.png?alt=media",
      },
    ],
  },
];
